@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/owl.carousel/dist/assets/owl.carousel.css";
@import "../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
.br-fontawesome .br-units {
  align-items: center;
}
.br-fontawesome .br-unit {
  font: normal normal normal 20px/1 FontAwesome;
  margin-right: 2px;
}
.br-fontawesome .br-unit:after {
  content: "";
  color: #d2d2d2;
}
.br-fontawesome .br-selected:after {
  color: #EDB867;
}
.br-fontawesome .br-active:after {
  color: #EDB867;
}

input.number-hide-button::-webkit-outer-spin-button,
input.number-hide-button::-webkit-inner-spin-button {
  display: none;
}

input.number-hide-button {
  -moz-appearance: textfield;
}

.file-btn {
  position: relative;
}

.file-btn input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.actions {
  padding: 5px 0;
}

.actions button {
  margin-right: 5px;
}

.upload-demo .upload-demo-wrap,
.upload-demo .upload-result,
.upload-demo.ready .upload-msg {
  display: none;
}

.upload-demo.ready .upload-demo-wrap {
  display: block;
}

.upload-demo.ready .upload-result {
  display: inline-block;
}

ckeditor.ng-invalid.ng-touched > div {
  border: 1px solid red;
}

mat-selection-list mat-list-option .mat-list-text > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .IE-padding-eng {
    padding-top: 4px !important;
  }

  .IE-padding-all {
    padding-top: 20px !important;
  }
}
.mvms-public.rtl #map .container .content {
  padding-right: 35px;
  background-position: right 10px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mvms-public.rtl .IE-padding,
.mvms-public.rtl .mat-radio-label-content {
    padding-top: 20px !important;
  }
  .mvms-public.rtl .mat-column-title, .mvms-public.rtl .mat-header-cell, .mvms-public.rtl .mat-cell, .mvms-public.rtl .mat-tab-link {
    padding-top: 12px !important;
  }
  .mvms-public.rtl .mat-form-field-hide-placeholder :-ms-input-placeholder.mat-input-element {
    line-height: 52px !important;
  }
  .mvms-public.rtl .IE-select,
.mvms-public.rtl .mat-form-field .mat-select-value-text {
    line-height: 38px !important;
  }
  .mvms-public.rtl .details {
    line-height: 32px;
  }
  .mvms-public.rtl .IE-feild-title {
    line-height: 49px;
  }
  .mvms-public.rtl .enq-title {
    padding-top: 6px !important;
  }
  .mvms-public.rtl .profileMenu .profile .user, .mvms-public.rtl .profileMenu.mat-menu-panel a.mat-raised-button span {
    line-height: 32px !important;
  }
}